import {useContext} from 'react';
import {
  GuestConsentContext,
  type GuestConsentContextValue,
} from './GuestConsentProvider';
import {type ConsentStatus} from './GuestConsentProviderDefinition';

type ToConsentKey<Key extends string> = Key extends `${infer Head}Consent`
  ? Head
  : never;

/**
 * Types of consent which can be interrogated
 */
export type ConsentType = ToConsentKey<
  keyof Omit<GuestConsentContextValue, 'endpoint'>
>;

/**
 * Extract the consent setting for the given consent type, if not within an
 * `GuestConsentContext` the `'default'` `ConsentStatus` is returned.
 */
export function useGuestConsent(type: ConsentType): ConsentStatus {
  const context = useContext(GuestConsentContext);
  const value = context?.[`${type}Consent`] ?? 'default';
  return value;
}
