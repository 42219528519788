import {
  LayoutModule,
  storage,
  useShowInstructions,
  type SchemaInstructionsHelper,
} from '@backstage-components/base';
import {useSubscription} from 'observable-hooks';
import {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  type FC,
  type PropsWithChildren,
  type Reducer,
} from 'react';
import type {JsonObject, JsonValue} from 'type-fest';
import {
  ComponentDefinition,
  isConsentStatus,
  reactName,
  type ConsentStatus,
} from './GuestConsentProviderDefinition';
import {removeAnalyticsToken} from './use-analytics-token';

export type GuestConsentProviderDefinition = LayoutModule<
  typeof reactName,
  GuestConsentProviderProps
>;

/**
 * @private exported for tests
 */
export const GuestConsentContext = createContext<
  GuestConsentContextValue | undefined
>(undefined);
GuestConsentContext.displayName = 'GuestConsentContext';

/**
 * Creates a React Context to hold information about guest consent related to
 * cookies and analytics.
 */
export const GuestConsentProvider: FC<
  PropsWithChildren<GuestConsentProviderProps>
> = (props) => {
  const {domainName} = props;
  const {broadcast, observable} = useShowInstructions(
    ComponentDefinition.instructions
  );
  const [state, dispatch] = useReducer(contextReducer, readInitialData());
  const {analyticsConsent} = state;
  // Pass instructions into the `contextReducer`
  useSubscription(observable, {next: dispatch});
  // Store consent state when it changes
  useEffect(() => {
    // base64 encode the data to make it slightly harder to fiddle with in
    // developer tools
    const stateValue = btoa(JSON.stringify(state));
    storage.setItem(storageKey(domainName), stateValue);
  }, [domainName, state]);
  // Clear the analytics token from cookies when consent changes
  useEffect(() => {
    if (analyticsConsent === 'rejected') {
      removeAnalyticsToken();
    }
  }, [analyticsConsent]);
  // Listen for changes to consent settings in other tabs
  useEffect(() => {
    const preferencesKey = storageKey(domainName);
    const onStorageChange = (e: StorageEvent): void => {
      if (e.key !== preferencesKey) {
        return;
      }
      // Otherwise read value and broadcast an update, the provider will then
      // receive the update from the local broker and update state
      const nextState = readInitialData(domainName);
      broadcast({
        type: 'GuestConsentProvider:on-save-preferences',
        meta: {analytics: nextState.analyticsConsent},
      });
    };
    window.addEventListener('storage', onStorageChange);
    return () => window.removeEventListener('storage', onStorageChange);
  }, [broadcast, domainName]);
  // Only change the context value when a constituent value changes
  const value: GuestConsentContextValue = useMemo(
    () => ({analyticsConsent, necessaryConsent: 'accepted'}),
    [analyticsConsent]
  );
  return (
    <GuestConsentContext.Provider value={value} children={props.children} />
  );
};

export interface GuestConsentProviderProps {
  /** Domain for which the guest consent is stored */
  domainName?: string;
}

export interface GuestConsentContextValue {
  /**
   * Whether the guest has provided consent for analytics information to be
   * collected and tied to a token identifier.
   */
  analyticsConsent: ConsentStatus;
  /**
   * Whether the guest has consented to cookies necessary for platform
   * operation. This is _always_ 'accepted'.
   */
  necessaryConsent: ConsentStatus;
}

type GuestConsentProviderAction = SchemaInstructionsHelper<
  typeof ComponentDefinition
>;

type GuestConsentProviderState = Omit<
  GuestConsentContextValue,
  'necessaryConsent'
>;

/**
 * Collect consent values and update them when analytics related instructions
 * are received.
 */
const contextReducer: Reducer<
  GuestConsentProviderState,
  GuestConsentProviderAction
> = (draft, action) => {
  switch (action.type) {
    case 'GuestConsentProvider:on-accept-all':
      return {analyticsConsent: 'accepted'};
    case 'GuestConsentProvider:on-reject-all':
      return {analyticsConsent: 'rejected'};
    case 'GuestConsentProvider:on-save-preferences':
      return {
        analyticsConsent: action.meta.analytics ?? draft.analyticsConsent,
      };
    default:
      return draft;
  }
};

/**
 * Get a valid `ConsentStatus` from any string possible string
 * @private exported for tests
 */
export const consentStatusProvider = (s?: string): ConsentStatus =>
  isConsentStatus(s) ? s : 'default';

/**
 * Get the value of `key` from `o` as a `ConsentStatus`
 * @private exported for tests
 */
export function extractConsentStatus(
  o: JsonObject,
  key: string
): ConsentStatus {
  const value = o[key];
  return typeof value === 'string'
    ? consentStatusProvider(value)
    : consentStatusProvider();
}

function readInitialData(domainName?: string): GuestConsentProviderState {
  const fallbackState: GuestConsentProviderState = {
    analyticsConsent: 'default',
  };
  try {
    const initial: JsonValue = JSON.parse(
      // e30= is "{}" base64 encoded
      atob(storage.getItem(storageKey(domainName)) ?? 'e30=')
    );
    if (
      typeof initial === 'object' &&
      initial !== null &&
      !Array.isArray(initial)
    ) {
      return {
        analyticsConsent: extractConsentStatus(initial, 'analyticsConsent'),
      };
    } else {
      return fallbackState;
    }
  } catch {
    // If there is an exception while getting initial data use the fallback
    return fallbackState;
  }
}

/**
 * Compute the key used in local storage for saving consent details based on
 * the provided `domainName`
 * @private exported for tests
 */
export function storageKey(domainName?: string): string {
  return ['lcd', `${domainName}`, 'data-consent-v1'].join('/');
}
