export {
  GuestConsentContext,
  GuestConsentProvider,
} from './GuestConsentProvider';
export {useAnalyticsToken} from './use-analytics-token';
export {useGuestConsent} from './use-guest-consent';

import {Registry} from '@backstage-components/base';
import {ComponentDefinition} from './GuestConsentProviderDefinition';
import {GuestConsentProviderModule} from './GuestConsentProviderModule';
Registry.register(ComponentDefinition, GuestConsentProviderModule);

export const AnalyticsContainerInstructionSchema =
  ComponentDefinition.instructions;
