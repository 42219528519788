import {storage} from '@backstage-components/base';
import Cookie from 'js-cookie';
import {useRef} from 'react';
import {useGuestConsent} from './use-guest-consent';

/**
 * Read analytics token from storage, if one does not exist create one. If the
 * guest has not provided consent for analytics collection returns `undefined`.
 */
export function useAnalyticsToken(): string | undefined {
  const token = useRef(readAnalyticsToken());
  const consent = useGuestConsent('analytics');
  // TODO: Once the cookie banner is implemented and not feature flagged
  // this check should be changed to `consent !== 'accepted'`
  // https://linear.app/lcdbackstage/issue/BSG-1849
  if (consent === 'rejected') {
    return undefined;
  } else if (
    typeof token.current === 'undefined' &&
    typeof window?.crypto?.randomUUID === 'function'
  ) {
    // If not explicitly anonymous and token is missing then create one
    const nextToken = window.crypto.randomUUID();
    updateAnalyticsToken(nextToken);
    token.current = nextToken;
  }
  return token.current ?? undefined;
}

/**
 * Reads the analytics token from cookies or local storage. An analytics token
 * Cookie is preferred over the value in local storage, meaning if an analytics
 * token exists in both cookies and local storage the value in cookies will be
 * returned.
 * @private exported for tests
 */
export function readAnalyticsToken(): string | undefined {
  const initialToken = Cookie.get('analytics-token');
  return initialToken ?? storage.getItem('lcd/analytics-token') ?? undefined;
}

/**
 * Remove the analytics token from both cookie storage an local storage.
 */
export function removeAnalyticsToken(): void {
  Cookie.remove('analytics-token');
  storage.removeItem('lcd/analytics-token');
}

/**
 * Set the given analytics token (`token`) in cookie storage.
 */
function updateAnalyticsToken(token?: string | null): void {
  if (typeof token === 'string') {
    Cookie.set('analytics-token', token, {
      expires: 30, // expires in ~1 month
      sameSite: 'strict',
      secure: true,
    });
  }
}
