import {createComponentAdmin, PublishesTo} from '@backstage-components/base';
import {Type, type Static} from '@sinclair/typebox';

export const reactName = 'GuestConsentProvider';
export const name = reactName;

const ConsentStatus = Type.Union([
  Type.Literal('accepted'),
  Type.Literal('rejected'),
  Type.Literal('default'),
]);

export type ConsentStatus = Static<typeof ConsentStatus>;

/**
 * Type guard to check that `input` can be used as ConsentStatus.
 */
export function isConsentStatus(input?: string): input is ConsentStatus {
  return ConsentStatus.anyOf.some((s) => s.const === input);
}

export const GuestConsentProviderInstructionSchema = Type.Union([
  PublishesTo({
    topic: `${reactName}:on-accept-all`,
    description: 'Guest has accepted all cookies',
    options: {'$lcd-flow-ignore': true},
  }),
  PublishesTo({
    topic: `${reactName}:on-reject-all`,
    description: 'Guest has rejected all cookies',
    options: {'$lcd-flow-ignore': true},
  }),
  PublishesTo({
    topic: `${reactName}:on-save-preferences`,
    description:
      'Guest has customized and saved their content privacy preferences',
    meta: {analytics: Type.Optional(ConsentStatus)},
    options: {'$lcd-flow-ignore': true},
  }),
]);

export const ComponentDefinition = createComponentAdmin({
  id: '781471d1-c91f-41dc-a87c-c08c0f4a5e29',
  category: 'internal',
  defaultFieldData: {},
  description: 'Manages guest consent information.',
  instructions: GuestConsentProviderInstructionSchema,
  reactName,
  name,
  schema: Type.Object({}),
  slotConfiguration: {},
  slug: reactName,
  version: 1,
}).build();
